<script setup lang="ts">
import { onBeforeMount, getCurrentInstance, computed } from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { useMainStore } from "@/store/main/index";
import type { IndxInterface } from "@/store/main/types/StateType";

const mainStore = useMainStore();
const indx = useState<IndxInterface>("data");

const siteKey = computed(() => indx.value?.siteSettings.website_captcha_key);

onBeforeMount(() => {
  if (mainStore.isGrecaptchaLoaded) {
    return;
  }

  const instance = getCurrentInstance();
  instance?.appContext.app.use(VueReCaptcha, {
    siteKey: siteKey.value,
    loaderOptions: {
      useRecaptchaNet: true,
    },
  });

  mainStore.isGrecaptchaLoaded = true;
});
</script>

<template></template>
