import { setCookie } from "../utils/storage";

export const useRecaptcha = async (): Promise<string | undefined> => {
    if (!window.grecaptcha) {
        console.warn("Can't find grecaptcha object");
        return undefined;
    }

    let token: string | undefined;
    try {
        token = await (window.grecaptcha as any)?.execute();
    } catch (err) {
        console.warn(err);
    }

    setCookie("grecaptcha", token || "");

    return token;
};
