/**
 * @author Beka Chkhaidze
 * _______________________
 *
 * @summary Simplified version of @vuelidate library
 * @usage
 * initial state
  const formData = ref({
    name: null,
    phone: null,
    email: null,
    privacy: null,
  });

  // setup validator
  const { isFormValid, validationStarted, errors, doValidation } = useFormValidator({
    formData,
    rules,
  });

  // do validation
  const onSubmit = async () => {
    
    doValidation(true);
    if (!isFormValid.value) return;

  // UI part
  <Button @click.prevent="onSubmit" :isDisabled="!isFormValid && validationStarted" />
 */

import { watch, ref } from "vue";
import { useVuelidate, ErrorObject } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { useBookingStore } from "@/store/booking";
import { useMainStore } from "@/store/main/index";

import type { CountryCode } from "libphonenumber-js";
import type { Ref } from "vue";

import parsePhoneNumber from "libphonenumber-js";

type ValidationRule<T> = {
    [K in keyof T]: ((value: T[K]) => boolean) | Record<string, any>;
};

type Props<T> = {
    rules: ValidationRule<T>;
    formData: Ref<T>;
    watchChanges?: boolean;
};

/**
 * @warning Don't edit this.
 * edit/create custom rules in your component, not here
 */
export const DEFAULT_RULES = {
    name: { required, minLength: minLength(2) },
    phone: { required, minLength: minLength(3) },
    email: { email, minLength: minLength(5) },
};

export const isValidPhoneNumberForCountry = (
    phoneNumberString: string,
    country: CountryCode
): boolean => {
    const phoneNumber = parsePhoneNumber(phoneNumberString, {
        defaultCountry: country,
        // Demand that the entire input string must be a phone number.
        // Otherwise, it would "extract" a phone number from an input string.
        extract: false,
    });
    if (!phoneNumber) {
        return false;
    }
    if (phoneNumber.country !== country) {
        return false;
    }
    return phoneNumber.isValid();
};

/**
 * @getError :errorText="v$.[KEY]?.$errors?.[0]?.$message"
 */
export const useFormValidator = <T extends Record<string, any>>({
    rules,
    formData,
    watchChanges,
}: Props<T>) => {
    const errors = ref<Record<keyof T, Ref<string> | string>>(
        {} as Record<keyof T, Ref<string> | string>
    );
    const validationStarted = ref(false);
    const isFormValid = ref(false);
    const stopValidation = ref(false);
    const isValidationFromBtnSubmit = ref(false);
    const hasLiveReload = watchChanges ?? true;
    const validator = useVuelidate(rules, formData);

    const bookingStore = useBookingStore();
    const mainStore = useMainStore();

    const errorsReducer = <T extends Record<string, any>>(
        acc: Record<keyof T, Ref<string> | string>,
        curr: ErrorObject
    ) => {
        const key = curr.$property;
        const valueInFormdata = formData.value[key];

        if (valueInFormdata != null || isValidationFromBtnSubmit.value) {
            acc[curr.$property as keyof T] = curr.$message;
        }

        return acc;
    };

    const resetErors = () => {
        Object.keys(errors.value).forEach((el) => {
            errors.value[el] = null;
        });
        isFormValid.value = true;
    };
    const resetState = () => {
        stopValidation.value = true;
        validationStarted.value = false;

        Object.keys(formData.value).forEach((el) => {
            // @ts-ignore
            formData.value[el] = null;
        });

        resetErors();
        setTimeout(() => {
            stopValidation.value = false;
        }, 100);

        isFormValid.value = false;
    };

    const doValidation = async (_isValidationFromBtnSubmit = false) => {
        isValidationFromBtnSubmit.value =
            isValidationFromBtnSubmit.value || _isValidationFromBtnSubmit;

        let isPhoneNumberFormatValid: boolean;
        if (stopValidation?.value) return;

        isFormValid.value = await validator.value.$validate();
        validationStarted.value = true;
        errors.value = Object.values(validator.value.$errors).reduce(
            errorsReducer,
            {} as Record<keyof T, Ref<string> | string>
        );

        if (formData.value.phone) {
            try {
                isPhoneNumberFormatValid = isValidPhoneNumberForCountry(
                    formData.value.phone,
                    bookingStore.dial
                );
            } catch (e) {
                isPhoneNumberFormatValid = false;
            }

            if (!isPhoneNumberFormatValid) {
                errors.value.phone = "invalid format for selected country";
                isFormValid.value = false;
            }
        }
    };

    if (hasLiveReload) {
        watch(
            formData,
            async () => {
                /**
                 * @note
                 * stop validation when state is resetting
                 * otherwise it will raise validation errors when we clear state
                 */
                doValidation();
            },
            { deep: true }
        );
    }

    watch(
        () => bookingStore.dial,
        (n) => {
            let isPhoneNumberFormatValid: boolean;
            if (formData.value.phone) {
                try {
                    isPhoneNumberFormatValid = isValidPhoneNumberForCountry(
                        formData.value.phone,
                        n
                    );
                } catch (e) {
                    isPhoneNumberFormatValid = false;
                }

                if (!isPhoneNumberFormatValid) {
                    errors.value.phone = "invalid format for selected country";
                    isFormValid.value = false;
                } else {
                    errors.value.phone = null;
                    isFormValid.value = true;
                }
            }
        }
    );

    return {
        isFormValid,
        validator,
        errors,
        validationStarted,
        isValidationFromBtnSubmit,
        resetState,
        doValidation,
    };
};
